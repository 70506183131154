*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
  font-weight: inherit;
}
img,
svg {
  max-width: 100%;
  height: auto;
}

ul {
  list-style-type: none;
}

html {
  scroll-behavior: smooth;
}

body {
  height: 100%;
  line-height: 1;

  overflow-x: hidden;
}
