.app_dark_theme {
  --bg-color: #1e1e1e;
  --inverted-bg-color: #fff;
  --primary-color: #fff;
  --secondary-color: #049604;
  --inverted-primary-color: #004eff;
  --inverted-secondary-color: #0232c2;

  --banner-p-color: #9b999e;
  --banner-blueLink: #2387f8;

  --footer-background: #1e1e1e;
}
