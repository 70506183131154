.header {
  background: var(--bg-color);
  position: relative;
  z-index: 300;
}

.container {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--inverted-bg-color);
}

.logo {
}

.logoPride path {
  fill: var(--primary-color);
}

.buttons {
  display: flex;
  align-items: center;
  gap: 8px;

  button {
    color: var(--inverted-bg-color);
    background: none;
    cursor: pointer;
  }
}

.languages {
  display: flex;
}

.btnNav {
  display: none;
  background: none;
}

.burgerIcon {
  path {
    stroke: var(--inverted-bg-color);
  }
}

@media (max-width: 960px) {
  .btnNav {
    display: flex;
  }
}

@media (max-width: 640px) {
}
