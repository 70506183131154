footer {
  background: var(--footer-background);
}

.Footer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 40px;
}

.topFooter {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 10px;

  h3 {
    color: #7c8082;
    font-size: 12px;
    font-weight: 300;
  }
}

.colList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.listLink {
  color: #ededed;
  font-size: 12px;
  font-weight: 400;
}

.bottomFooter {
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-top: 1px solid #9b999e;
}

.logoPride path {
  fill: var(--primary-color);
}

.copyright {
  color: #9b999e;
  font-size: 12px;
  font-weight: 700;
}

.pdfLinks {
  display: flex;
  align-items: center;
  gap: 20px;

  a {
    color: #ededed;
    font-size: 12px;
    font-weight: 400;
  }
}

@media (max-width: 640px) {
  .bottomFooter {
    flex-direction: column;
  }
}
