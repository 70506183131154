.Layout {
  max-width: 1200px;
  margin: 0 auto;
  padding-inline: 20px; // ???
  width: 100%;
}

.PageLayout {
  max-width: 1200px;
  margin: 0 auto;
}
