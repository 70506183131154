.app_light_theme {
  --bg-color: #fff;
  --inverted-bg-color: #1e1e1e;
  --primary-color: #000;
  --secondary-color: #0449e0;
  --inverted-primary-color: #04ff04;
  --inverted-secondary-color: #049604;

  --banner-p-color: #9b999e;
  --banner-blueLink: #2387f8;

  --footer-background: #1e1e1e;
}
