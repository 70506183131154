.navBar {
  width: 100%;
  max-width: 500px;

  a {
    color: var(--inverted-bg-color);
  }

  .linkList {
    display: inline;

    hr {
      margin: 0;
      height: 1px;
      background: #fff;
      border: none;
      transition: 0.3s ease-in-out;
    }

    .hoverLinkContainer {
      display: inline;
      padding: 21px 0;

      &:hover {
        .subMenu {
          pointer-events: initial;
          z-index: 99;
          max-height: 500px;

          .subContainer {
            opacity: 1;
          }
        }
      }
    }
  }
}

.navLink {
  display: inline-block;
  margin: auto;
  text-transform: uppercase;
  text-align: center;
  padding: 12px 0;

  &.activeLink {
    text-decoration: underline;
  }
}

.subMenu {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 64px;
  background-color: var(--bg-color);
  transition: all ease 0.8s;
  z-index: -999;
  max-height: 0;
  pointer-events: none;
  overflow: hidden;
}

.subContainer {
  display: flex;
  gap: 24px;
  padding: 20px;
  opacity: 0;
  transition: opacity ease 0.5s;
}

.subCol {
  display: flex;
  flex-direction: column;
  gap: 10px;

  h4 {
    font-weight: 300;
    font-size: 13px;
    color: #7c8082;
    line-height: 16.34px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  a {
    font-size: 12px;
    line-height: 16.34px;
  }

  .subLinkBold {
    font-size: 20px;
    font-weight: 700;
    line-height: 27.24px;
  }
}

@media (max-width: 960px) {
  .btnNav {
    display: block;
  }

  .navLink {
  }

  .navBar {
    background: var(--bg-color);
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 64px;
    height: initial;
    transition:
      max-height 0.5s ease-in-out,
      opacity 0.5s;
    z-index: -999;
    max-height: 0;
    max-width: none;
    overflow: hidden;

    .linkList {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-height: 0;
      z-index: -999;
      transition:
        max-height 0.5s ease-in-out,
        opacity 0.5s;
      hr {
        display: none;
      }

      li {
        &:hover {
          .subMenu {
            pointer-events: none;
          }
        }
      }
    }
  }

  .show {
    z-index: 500;
    max-height: 500px;

    .linkList {
      max-height: 500px;
      z-index: 99;
    }
  }

  .navSlideHover {
    display: none;
  }

  .subMenu {
    display: none;
  }
}
