@import '_reset.css';
@import 'themes/light';
@import 'themes/dark';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import '~react-image-gallery/styles/scss/image-gallery.scss';

* {
  font-family: 'Open Sans', sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-content {
  flex: 1;
}

// react-image-gallery custom styles
.image-gallery-slide-wrapper {
  // aspect-ratio: 1 / 1;
  //background: #F8F8F8;
  // display: flex;
}

.image-gallery-swipe {
  // margin: auto;
}

.image-gallery-thumbnail {
  width: 68px;
  // aspect-ratio: 1 / 1;
  opacity: 0.6;
}

.image-gallery-content.fullscreen {
  //background: #F8F8F8;
}
